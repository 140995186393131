import English from "../../../lang/en.json";

const QUESTIONTYPE = {
  CHOICE: "choice",
  TEXT: "text",
  FLOAT: "float",
};
const RATINGOPTIONS = {
  1: "Strongly Disagree",
  2: "Disagree",
  3: "Neutral",
  4: "Agree",
  5: "Strongly Agree",
};
const RATINGOPTIONS1 = {
  1: "Strongly Agree",
  2: "Agree",
  3: "Neutral",
  4: "Disagree",
  5: "Strongly Disagree",
};
const prospectPayLoad = {
  //My Portfolio
  ques1: { id: 1, type: QUESTIONTYPE.CHOICE },
  ques1Other: { id: 1.1, type: QUESTIONTYPE.TEXT },
  ques2: { id: 2, type: QUESTIONTYPE.CHOICE },
  ques2Other: { id: 2.1, type: QUESTIONTYPE.TEXT },
  ques3: { id: 3, type: QUESTIONTYPE.CHOICE },
  ques4: { id: 4, type: QUESTIONTYPE.FLOAT },
  ques5: { id: 5, type: QUESTIONTYPE.CHOICE },
  ques5Year1: { id: 5.1, type: QUESTIONTYPE.FLOAT },
  ques5Year2: { id: 5.2, type: QUESTIONTYPE.FLOAT },
  ques5immediate: { id: 5.3, type: QUESTIONTYPE.FLOAT },
  ques5yr1: { id: 5.4, type: QUESTIONTYPE.FLOAT },
  ques5yr2: { id: 5.5, type: QUESTIONTYPE.FLOAT },
  ques6: { id: 6, type: QUESTIONTYPE.CHOICE },
  ques6Year1: { id: 6.1, type: QUESTIONTYPE.FLOAT },
  ques6Year2: { id: 6.2, type: QUESTIONTYPE.FLOAT },
  ques7: { id: 7, type: QUESTIONTYPE.CHOICE },
  ques7Amt: { id: 7.1, type: QUESTIONTYPE.FLOAT },

  //Portfolio Preferences
  ques8: { id: 8, type: QUESTIONTYPE.CHOICE },
  ques9: { id: 9, type: QUESTIONTYPE.CHOICE },
  ques10checkbox1: { id: 10.1, type: QUESTIONTYPE.CHOICE },
  ques10checkbox2: { id: 10.2, type: QUESTIONTYPE.CHOICE },
  ques10checkbox3: { id: 10.3, type: QUESTIONTYPE.CHOICE },
  ques10checkbox4: { id: 10.4, type: QUESTIONTYPE.CHOICE },
  ques10checkbox5: { id: 10.5, type: QUESTIONTYPE.CHOICE },
  ques10checkbox6: { id: 10.6, type: QUESTIONTYPE.CHOICE },

  //Investment Behaviour
  ques11: { id: 11, type: QUESTIONTYPE.CHOICE },
  ques12: { id: 12, type: QUESTIONTYPE.CHOICE },
  ques13: { id: 13, type: QUESTIONTYPE.CHOICE },
  ques14: { id: 14, type: QUESTIONTYPE.CHOICE },
  ques15: { id: 15, type: QUESTIONTYPE.CHOICE },
  ques16: { id: 16, type: QUESTIONTYPE.CHOICE },
  ques17: { id: 17, type: QUESTIONTYPE.CHOICE },
  ques18: { id: 18, type: QUESTIONTYPE.CHOICE },
  ques19: { id: 19, type: QUESTIONTYPE.CHOICE },
  otherNotes: { id: 19.1, type: QUESTIONTYPE.TEXT },

  //Suitability
  ques20: { id: 20, type: QUESTIONTYPE.CHOICE },
  ques20Assets: { id: 20.1, type: QUESTIONTYPE.TEXT },
  ques21Total: { id: 21.1, type: QUESTIONTYPE.FLOAT },
  ques21Intl: { id: 21.2, type: QUESTIONTYPE.FLOAT },

  ques21Cash: { id: 21.3, type: QUESTIONTYPE.FLOAT },
  ques21RealEstate: { id: 21.4, type: QUESTIONTYPE.FLOAT },
  ques21PvtEquity: { id: 21.5, type: QUESTIONTYPE.FLOAT },
  ques21FixedIncome: { id: 21.6, type: QUESTIONTYPE.FLOAT },
  ques21Equity: { id: 21.7, type: QUESTIONTYPE.FLOAT },

  ques22: { id: 22, type: QUESTIONTYPE.CHOICE },
  ques23: { id: 23, type: QUESTIONTYPE.CHOICE },
  ques24: { id: 24, type: QUESTIONTYPE.CHOICE },

  ques25: { id: 101, type: QUESTIONTYPE.CHOICE },
  ques26: { id: 102, type: QUESTIONTYPE.CHOICE },
  ques27: { id: 103, type: QUESTIONTYPE.CHOICE },
  ques28: { id: 104, type: QUESTIONTYPE.CHOICE },
  ques29: { id: 105, type: QUESTIONTYPE.CHOICE },
  ques30: { id: 106, type: QUESTIONTYPE.CHOICE },
  ques31: { id: 107, type: QUESTIONTYPE.CHOICE },
};

const clientPayLoad = {
  //My Portfolio
  ques1: { id: 1, type: QUESTIONTYPE.CHOICE },
  ques1Other: { id: 1.1, type: QUESTIONTYPE.TEXT },
  ques2: { id: 2, type: QUESTIONTYPE.CHOICE },
  ques2Other: { id: 2.1, type: QUESTIONTYPE.TEXT },
  ques3: { id: 3, type: QUESTIONTYPE.CHOICE },
  ques4: { id: 4, type: QUESTIONTYPE.FLOAT },
  ques5: { id: 5, type: QUESTIONTYPE.CHOICE },
  ques5Year1: { id: 5.1, type: QUESTIONTYPE.FLOAT },
  ques5Year2: { id: 5.2, type: QUESTIONTYPE.FLOAT },
  ques5immediate: { id: 5.3, type: QUESTIONTYPE.FLOAT },
  ques5yr1: { id: 5.4, type: QUESTIONTYPE.FLOAT },
  ques5yr2: { id: 5.5, type: QUESTIONTYPE.FLOAT },
  ques6: { id: 6, type: QUESTIONTYPE.CHOICE },
  ques6Year1: { id: 6.1, type: QUESTIONTYPE.FLOAT },
  ques6Year2: { id: 6.2, type: QUESTIONTYPE.FLOAT },
  ques7: { id: 7, type: QUESTIONTYPE.CHOICE },
  ques7Amt: { id: 7.1, type: QUESTIONTYPE.FLOAT },

  //Portfolio Preferences
  ques8: { id: 8, type: QUESTIONTYPE.CHOICE },
  ques9: { id: 9, type: QUESTIONTYPE.CHOICE },

  //Investment Behaviour
  ques11: { id: 10, type: QUESTIONTYPE.CHOICE },
  ques12: { id: 11, type: QUESTIONTYPE.CHOICE },
  ques13: { id: 12, type: QUESTIONTYPE.CHOICE },
  ques14: { id: 13, type: QUESTIONTYPE.CHOICE },
  ques15: { id: 14, type: QUESTIONTYPE.CHOICE },
  ques16: { id: 15, type: QUESTIONTYPE.CHOICE },
  ques17: { id: 16, type: QUESTIONTYPE.CHOICE },
  ques18: { id: 17, type: QUESTIONTYPE.CHOICE },
  ques19: { id: 18, type: QUESTIONTYPE.CHOICE },
  otherNotes: { id: 18.1, type: QUESTIONTYPE.TEXT },

  //Suitability
  ques20: { id: 19, type: QUESTIONTYPE.CHOICE },
  ques20Assets: { id: 19.1, type: QUESTIONTYPE.TEXT },
  ques21Total: { id: 20.1, type: QUESTIONTYPE.FLOAT },
  ques21Intl: { id: 20.2, type: QUESTIONTYPE.FLOAT },

  ques21Cash: { id: 20.3, type: QUESTIONTYPE.FLOAT },
  ques21RealEstate: { id: 20.4, type: QUESTIONTYPE.FLOAT },
  ques21PvtEquity: { id: 20.5, type: QUESTIONTYPE.FLOAT },
  ques21FixedIncome: { id: 20.6, type: QUESTIONTYPE.FLOAT },
  ques21Equity: { id: 20.7, type: QUESTIONTYPE.FLOAT },

  ques22: { id: 21, type: QUESTIONTYPE.CHOICE },
  ques23: { id: 22, type: QUESTIONTYPE.CHOICE },
  ques24: { id: 23, type: QUESTIONTYPE.CHOICE },

  ques25: { id: 101, type: QUESTIONTYPE.CHOICE },
  ques26: { id: 102, type: QUESTIONTYPE.CHOICE },
  ques27: { id: 103, type: QUESTIONTYPE.CHOICE },
  ques28: { id: 104, type: QUESTIONTYPE.CHOICE },
  ques29: { id: 105, type: QUESTIONTYPE.CHOICE },
  ques30: { id: 106, type: QUESTIONTYPE.CHOICE },
  ques31: { id: 107, type: QUESTIONTYPE.CHOICE },
};

const formatAnswers = (qType, question, answer) => {
  var frmtAnswer = English[answer] ? English[answer] : answer;

  if (qType === QUESTIONTYPE.FLOAT && typeof frmtAnswer === "string") {
    frmtAnswer = frmtAnswer.replaceAll(",", "");
    frmtAnswer = parseFloat(frmtAnswer); //remove , from numbers
  }
  if (qType === QUESTIONTYPE.CHOICE) {
    // to handle Yes or No options
    if (frmtAnswer === false || frmtAnswer === "no") frmtAnswer = "No";
    if (frmtAnswer === true || frmtAnswer === "yes") frmtAnswer = "Yes";

    // to handle Rating options
    if (
      (question === "ques11" || question === "ques13") &&
      RATINGOPTIONS1.hasOwnProperty(frmtAnswer)
    ) {
      frmtAnswer = RATINGOPTIONS1[frmtAnswer];
    } else if (RATINGOPTIONS.hasOwnProperty(frmtAnswer)) {
      frmtAnswer = RATINGOPTIONS[frmtAnswer];
    }
  }

  return frmtAnswer;
};

const prePayload = (formValues, instanceCode, isClient) => {
  const payloadJson = {};
  payloadJson.instanceCode = instanceCode;
  const arrResp = [];
  var invstAmt = formValues["My Portfolio"]["ques4"];
  invstAmt = invstAmt.replaceAll(",", "");
  invstAmt = parseFloat(invstAmt);

  for (let section in formValues) {
    for (let question in formValues[section]) {
      var answer = formValues[section][question];
      const tempPayLoad = isClient ? clientPayLoad : prospectPayLoad;

      if (tempPayLoad[question] && answer !== "" && answer !== undefined) {
        // multiple select answers
        if (question === "ques24") {
          const filteranswer = answer.filter((e) => e.status); //get only selected choices

          filteranswer.forEach((e) => {
            let qResp = {};
            qResp.questionNumber = tempPayLoad[question].id;
            qResp.questionType = tempPayLoad[question].type;
            qResp.response = English[e.message].trim();
            arrResp.push(qResp);
          });
        } else if (invstAmt < 10000000 && question.includes("ques10checkbox")) {
          continue;
          //skip question 10 to get the responses
        } else {
          let qResp = {};
          qResp.questionNumber = tempPayLoad[question].id;
          qResp.questionType = tempPayLoad[question].type;
          qResp.response = formatAnswers(
            tempPayLoad[question].type,
            question,
            answer
          );
          arrResp.push(qResp);
        }
      }
    }
  }

  payloadJson.riskResponses = arrResp;

  return payloadJson;
};

const preparePayLoad = (formValues, instanceCode, isClient) => {
  // console.log("formValues", formValues);
  const payload = prePayload(formValues, instanceCode, isClient);
  // console.log("payload", payload);
  return payload;
};
export default preparePayLoad;
