import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { FormattedMessage } from "react-intl";
import "./suitability.css";
import { Row, Col } from "react-bootstrap";
import { LeftArrow } from "../../icons";
import { useFormik } from "formik";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./ClientClassification.css";

import * as Yup from "yup";
import { getErrorRefToScrollIntoView } from "../../utils";
import Info from "../../icons/Info";

const genError = () => {
  return (
    <FormattedMessage id="error.gen" defaultMessage="This field is required" />
  );
};

const schema = Yup.object().shape({
  ques25: Yup.string().required(genError),
  ques26: Yup.string().required(genError),
  ques27: Yup.string().required(genError),
  ques28: Yup.string().required(genError),
  ques29: Yup.string().required(genError),
  ques30: Yup.string().required(genError),
  ques31: Yup.string().required(genError),
});

const ClientClassification = forwardRef((props, ref) => {
  const currentTabName = "Client Classification";
  const { handleActiveTab, formikValues, setFormValues, setIsRetail } = props;
  const ClientClassifications = JSON.parse(
    sessionStorage.getItem("ClientClassification")
  );
  const currentTabVals = formikValues[currentTabName];
  const [selected, setSelected] = useState({
    qTwentyFive: "",
    qTwentySix: "",
    qTwentySeven: "",
    qTwentyEight: "",
    qTwentyNine: "",
    qThirty: "",
    qThirtyOne: "",
  });

  useEffect(() => {
    let temp = document.getElementsByClassName("main-container");
    if (temp.length > 0) temp[temp.length - 1].scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setSelected({
      qTwentyFive: formikValues[currentTabName].ques25 === "yes" ? "1" : "",
      qTwentySix: formikValues[currentTabName].ques26 === "yes" ? "1" : "",
      qTwentySeven: formikValues[currentTabName].ques27 === "yes" ? "1" : "",
      qTwentyEight: formikValues[currentTabName].ques28 === "yes" ? "1" : "",
      qTwentyNine: formikValues[currentTabName].ques29 === "yes" ? "1" : "",
      qThirty: formikValues[currentTabName].ques30 === "yes" ? "1" : "",
      qThirtyOne: formikValues[currentTabName].ques31 === "yes" ? "1" : "",
    });

    if (
      formikValues[currentTabName].ques25 === "no" &&
      formikValues[currentTabName].ques26 === "no" &&
      formikValues[currentTabName].ques27 === "no" &&
      formikValues[currentTabName].ques28 === "no" &&
      formikValues[currentTabName].ques29 === "no" &&
      formikValues[currentTabName].ques30 === "no" &&
      formikValues[currentTabName].ques31 === "no"
    ) {
      setIsRetail(true);
    } else {
      setIsRetail(false);
    }
  }, [formikValues[currentTabName]]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ques25:
        ClientClassifications && ClientClassifications.ques25
          ? ClientClassifications.ques25
          : currentTabVals.ques25,
      ques26:
        ClientClassifications && ClientClassifications.ques26
          ? ClientClassifications.ques26
          : currentTabVals.ques26,
      ques27:
        ClientClassifications && ClientClassifications.ques27
          ? ClientClassifications.ques27
          : currentTabVals.ques27,
      ques28:
        ClientClassifications && ClientClassifications.ques28
          ? ClientClassifications.ques28
          : currentTabVals.ques28,
      ques29:
        ClientClassifications && ClientClassifications.ques29
          ? ClientClassifications.ques29
          : currentTabVals.ques29,
      ques30:
        ClientClassifications && ClientClassifications.ques30
          ? ClientClassifications.ques30
          : currentTabVals.ques30,
      ques31:
        ClientClassifications && ClientClassifications.ques31
          ? ClientClassifications.ques31
          : currentTabVals.ques31,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      sessionStorage.setItem("ClientClassification", JSON.stringify(values));
      setFormValues(values);
      handleActiveTab("My Portfolio");
    },
    validateOnMount: true,
  });

  useImperativeHandle(ref, () => ({
    validateClientclassification: () => {
      return {
        ...formik,
      };
    },
    submitClientclassification: () => {
      return formik.submitForm();
    },
    touchedMyportfolio: () => {
      formik.setFieldTouched("ques1", true);
      formik.setFieldTouched("ques1Other", true);
      formik.setFieldTouched("ques2", true);
      formik.setFieldTouched("ques2Other", true);
      formik.setFieldTouched("ques3", true);
      formik.setFieldTouched("ques4", true);
      formik.setFieldTouched("ques5", true);
      formik.setFieldTouched("ques5Year1", true);
      formik.setFieldTouched("ques5immediate", true);
      formik.setFieldTouched("ques5yr1", true);
      formik.setFieldTouched("ques5yr2", true);
      formik.setFieldTouched("ques5Year2", true);
      formik.setFieldTouched("ques6", true);
      formik.setFieldTouched("ques6Year1", true);
      formik.setFieldTouched("ques6Year2", true);
      formik.setFieldTouched("ques7", true);
      formik.setFieldTouched("ques7Amt", true);
    },
  }));

  useEffect(() => {
    if (formik.isSubmitting) {
      let errors = Object.keys(formik.errors);
      if (errors.length > 0) {
        getErrorRefToScrollIntoView(errors[0]);
      }
    }
  }, [formik.isSubmitting]);

  const setFormikValue = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  // Add this function to determine tooltip placement
  const getTooltipPlacement = () => {
    // Check if mobile/tablet
    if (window.innerWidth <= 768) {
      // Get the element's position relative to viewport
      const element = document.activeElement;
      if (element) {
        const rect = element.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        // If element is in the bottom half of screen, show tooltip on top
        // Otherwise show it on bottom
        return rect.top > viewportHeight / 2 ? "top" : "bottom";
      }
      return "top"; // default for mobile
    }
    return "right"; // default for desktop
  };

  // Add state to force tooltip update
  const [tooltipPlacement, setTooltipPlacement] = useState(
    getTooltipPlacement()
  );

  // Update placement on window resize
  useEffect(() => {
    const handleResize = () => {
      setTooltipPlacement(getTooltipPlacement());
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleResize);
    };
  }, []);

  return (
    <div>
      <h2
        style={{
          fontSize: "24px",
          marginBottom: "32px",
          fontWeight: "500",
        }}
      >
        <FormattedMessage
          id="q.retail.text.header"
          defaultMessage="q.retail.text.header"
        />
      </h2>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <div className="question-wrapper">
          <p className="q-text">
            <FormattedMessage id="q.twentyFive" defaultMessage="q.twentyFive" />
          </p>
          <Row className="ans-row">
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="yes"
                  defaultChecked={formik.values.ques25 === "yes"}
                  name="ques25"
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qTwentyFive: "1" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.yes" defaultMessage="q.ans.yes" />
              </label>
            </Col>
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="no"
                  defaultChecked={formik.values.ques25 === "no"}
                  name="ques25"
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qTwentyFive: "2" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.no" defaultMessage="q.ans.no" />
              </label>
            </Col>
          </Row>

          {formik.touched.ques25 && formik.errors.ques25 ? (
            <div className="validation-error margintopn25">
              {formik.errors.ques25}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="question-wrapper">
          <p className="q-text">
            <FormattedMessage id="q.twentySix" defaultMessage="q.twentySix" />
          </p>

          <Row className="ans-row">
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="yes"
                  defaultChecked={formik.values.ques26 === "yes"}
                  name="ques26"
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qTwentySix: "1" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.yes" defaultMessage="q.ans.yes" />
              </label>
            </Col>
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="no"
                  defaultChecked={formik.values.ques26 === "no"}
                  name="ques26"
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qTwentySix: "2" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.no" defaultMessage="q.ans.no" />
              </label>
            </Col>
          </Row>

          {formik.touched.ques26 && formik.errors.ques26 ? (
            <div className="validation-error margintopn25">
              {formik.errors.ques26}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="question-wrapper">
          <p className="q-text">
            <FormattedMessage
              id="q.twentySeven"
              defaultMessage="q.twentySeven"
            />
          </p>

          <Row className="ans-row">
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="yes"
                  defaultChecked={formik.values.ques27 === "yes"}
                  name="ques27"
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qTwentySeven: "1" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.yes" defaultMessage="q.ans.yes" />
              </label>
            </Col>
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="no"
                  defaultChecked={formik.values.ques27 === "no"}
                  name="ques27"
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qTwentySeven: "2" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.no" defaultMessage="q.ans.no" />
              </label>
            </Col>
          </Row>

          {formik.touched.ques27 && formik.errors.ques27 ? (
            <div className="validation-error margintopn25">
              {formik.errors.ques27}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="question-wrapper">
          <p className="q-text">
            <FormattedMessage
              id="q.twentyEight"
              defaultMessage="q.twentyEight"
            />
            <OverlayTrigger
              placement={tooltipPlacement}
              overlay={
                <Tooltip>
                  <div className="custom-tooltip-container">
                    <ul
                      style={{
                        padding: "0 0 0 20px",
                        margin: "0",
                        listStyleType: "disc",
                      }}
                    >
                      {["a", "b", "c", "d", "e", "f", "g", "h", "i"].map(
                        (item) => (
                          <li
                            key={item}
                            style={{
                              marginBottom: "12px",
                              paddingLeft: "8px",
                              position: "relative",
                              lineHeight: "1.8",
                            }}
                          >
                            <FormattedMessage
                              id={`q.twentyEight.tooltip.${item}`}
                              defaultMessage={`q.twentyEight.tooltip.${item}`}
                            />
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </Tooltip>
              }
            >
              <span>
                <Info />
              </span>
            </OverlayTrigger>
          </p>

          <Row className="ans-row">
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="yes"
                  defaultChecked={formik.values.ques28 === "yes"}
                  name="ques28"
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qTwentyEight: "1" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.yes" defaultMessage="q.ans.yes" />
              </label>
            </Col>
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="no"
                  defaultChecked={formik.values.ques28 === "no"}
                  name="ques28"
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qTwentyEight: "2" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.no" defaultMessage="q.ans.no" />
              </label>
            </Col>
          </Row>

          {formik.touched.ques28 && formik.errors.ques28 ? (
            <div className="validation-error margintopn25">
              {formik.errors.ques28}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="question-wrapper">
          <p className="q-text">
            <FormattedMessage id="q.twentyNine" defaultMessage="q.twentyNine" />
          </p>

          <Row className="ans-row">
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="yes"
                  defaultChecked={formik.values.ques29 === "yes"}
                  name="ques29"
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qTwentyNine: "1" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.yes" defaultMessage="q.ans.yes" />
              </label>
            </Col>
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="no"
                  defaultChecked={formik.values.ques29 === "no"}
                  name="ques29"
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qTwentyNine: "2" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.no" defaultMessage="q.ans.no" />
              </label>
            </Col>
          </Row>

          {formik.touched.ques29 && formik.errors.ques29 ? (
            <div className="validation-error margintopn25">
              {formik.errors.ques29}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="question-wrapper">
          <p className="q-text">
            <FormattedMessage id="q.thirty" defaultMessage="q.thirty" />
          </p>
          <p style={{ display: "block" }}>
            <span className="smallcontent">
              <FormattedMessage
                id="q.thirty.subtextA"
                defaultMessage="q.thirty.subtextA"
              />
            </span>
            <div className="smallcontent">
              <FormattedMessage
                id="q.thirty.subtextB"
                defaultMessage="q.thirty.subtextB"
              />
            </div>
          </p>

          <Row className="ans-row">
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="yes"
                  defaultChecked={formik.values.ques30 === "yes"}
                  name="ques30"
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qThirty: "1" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.yes" defaultMessage="q.ans.yes" />
              </label>
            </Col>
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="no"
                  defaultChecked={formik.values.ques30 === "no"}
                  name="ques30"
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qThirty: "2" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.no" defaultMessage="q.ans.no" />
              </label>
            </Col>
          </Row>

          {formik.touched.ques30 && formik.errors.ques30 ? (
            <div className="validation-error margintopn25">
              {formik.errors.ques30}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="question-wrapper">
          <p className="q-text">
            <FormattedMessage id="q.thirtyOne" defaultMessage="q.thirtyOne" />
          </p>

          <Row className="ans-row">
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="yes"
                  defaultChecked={formik.values.ques31 === "yes"}
                  name="ques31"
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qThirtyOne: "1" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.yes" defaultMessage="q.ans.yes" />
              </label>
            </Col>
            <Col xs={12} sm={"auto"} className="ans-col">
              <label className="radio-wrapper">
                <input
                  type="radio"
                  value="no"
                  defaultChecked={formik.values.ques31 === "no"}
                  name="ques31"
                  onClick={(e) => {
                    setFormikValue(e);
                    setSelected((prev) => {
                      return { ...prev, ...{ qThirtyOne: "2" } };
                    });
                  }}
                />
                <span className="radio-btn"></span>
                <FormattedMessage id="q.ans.no" defaultMessage="q.ans.no" />
              </label>
            </Col>
          </Row>

          {formik.touched.ques31 && formik.errors.ques31 ? (
            <div className="validation-error margintopn25">
              {formik.errors.ques31}
            </div>
          ) : (
            ""
          )}
        </div>

        {/* Responsive Question 7 End */}
        <div className="buttonContainer">
          <Row>
            <Col xs={12} md={6}>
              <button className="next-btn btn-block" type="submit">
                <FormattedMessage
                  id="btn.myPortfolio"
                  defaultMessage="btn.myPortfolio"
                />{" "}
                <span className="right-arrow">
                  <LeftArrow
                    rotate={180}
                    color="#b99855"
                    width={12}
                    height={"auto"}
                  />
                </span>
                <span className="left-arrow">
                  <LeftArrow
                    rotate={0}
                    color="#b99855"
                    width={12}
                    height={"auto"}
                  />
                </span>
              </button>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
});
export default ClientClassification;
